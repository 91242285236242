
/**
 * @name: 公告列表
 * @author: itmobai
 * @date: 2023-07-19 13:53
 * @description：公告列表
 * @update: 2023-07-19 13:53
 */
import { Vue, Component } from "vue-property-decorator"
import { INotice, INoticeParam } from "@/apis/notice/types"
import { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";
import { mallNoticeQueryApi, mallNoticeCreateApi, mallNoticeDetailApi, mallNoticeModifyApi, mallNoticeRemoveApi, mallNoticeSetUpStatusApi } from "@/apis/notice"

@Component({})
export default class NoticePage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: INotice[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: INoticeParam = {
    page: 1,
    limit: 10
  }
  // 表单数据
  modelForm: Partial<INotice> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    dialogWidth: '800px',
    addTitle: '新增公告',
    column: [
      {
        label: "公告ID",
        prop: "id",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true
      },
      {
        label: "公告内容",
        prop: "noticeContent",
        type: "textarea",
        search: true,
        overHidden: true,
        maxlength: 50,
        showWordLimit: true,
        rules: [
          {required: true, message: '请输入公告介绍', trigger: 'blur'}
        ]
      },
      {
        label: "经办人",
        prop: "editName",
        align: "center",
        editHide: true,
        addHide: true,
        search: true
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        width: 180,
        editHide: true,
        addHide: true,
        search: true,
        type: "daterange"
      },
      {
        label: "展示",
        prop: "status",
        slot: true,
        addHide: true,
        editHide: true,
        width: 150,
        align: 'center',
        search: true,
        type: "switch",
        dicData: [
          {
            label: "是",
            value: 1
          },
          {
            label: "否",
            value: 2
          }
        ]
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const form: INoticeParam = deepCopy(this.queryForm)
    if (form.addTime && form.addTime.length) {
      form.startTime = form.addTime[0]
      form.endTime = form.addTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.addTime
    mallNoticeQueryApi(form).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total;
      this.tableLoading = false
    })
  }

  openEdit (row: INotice, index: number) {
    mallNoticeDetailApi(row.id as string).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  rowSave (form: INotice, done: Function, loading: Function) {
    mallNoticeCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: INotice, done: Function, loading: Function) {
    mallNoticeModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowDel (form: INotice, index: number) {
    mallNoticeRemoveApi(form.id).then(e => {
      this.$message.success("操作成功!")
      this.getList()
    })
  }

  openDetail (row: INotice, index: number) {
    mallNoticeDetailApi(row.id as string).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  statusChange (id: string, isShow: number) {
    mallNoticeSetUpStatusApi(id).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  created () {
    this.getList()
  }
}
