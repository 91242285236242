/**
 * @name: 公告接口
 * @author: itmobai
 * @date: 2023-07-19 13:56
 * @description：公告接口
 * @update: 2023-07-19 13:56
 */

import { get, postJ } from "@/request"
import { INotice, INoticeParam } from "./types"
import { IPageRes } from "../page"

/**
 * 查询公告列表
 * @param query
 * @returns
 */
export const mallNoticeQueryApi = (query: INoticeParam) => get<IPageRes<INotice[]>>("/mall/notice/query", query)
/**
 * 创建公告
 * @param data 创建对象
 * @returns
 */
export const mallNoticeCreateApi = (data: INotice) => postJ("/mall/notice/create", data)
/**
 * 修改公告
 * @param data 创建对象
 * @returns
 */
export const mallNoticeModifyApi = (data: INotice) => postJ("/mall/notice/modify", data)
/**
 * 修改状态
 * @param id
 * @returns
 */
export const mallNoticeSetUpStatusApi = (id: string) => get("/mall/notice/setUpStatus/" + id)
/**
 * 删除公告
 * @param id
 * @returns
 */
export const mallNoticeRemoveApi = (id: string) => get("/mall/notice/remove/" + id)
/**
 * 查询详情
 * @param id
 * @returns
 */
export const mallNoticeDetailApi = (id: string) => get("/mall/notice/detail/" + id)
